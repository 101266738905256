import React from "react"
import PostList from "../components/PostList"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import VideoBanner from "../components/VideoBanner/VideoBanner"
import SectionBox from "../components/SectionBox"
import SectionSeparator from "../components/SectionHorizontalSeparator"
import Anim from "../components/Anim"
import TagBox from "../components/TagBox"
import { StaticImage, getImage } from "gatsby-plugin-image"
import SectionBannerImage from "../components/SectionBanner"

const Index_org = ({ pageContext, location }) => {
  const { lang, metaInfo, slug, otherLang, defLang } = pageContext
  // console.log("INDEX COMPOENENT LANG: ", slug)
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "yachtBg.jpeg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `)
  const image = getImage(data.file)
  // const metaInfo = pageContext.metaInfo
  // const slug = pageContext.slug
  // const otherLang = pageContext.otherLang
  // const defLang = pageContext.defLang
  return (
    <Layout
      location={location}
      metaInfo={metaInfo}
      lang={lang}
      slug={slug}
      ohterLang={otherLang}
      defLang={defLang}
    >
      <VideoBanner lang={lang} />
      <SectionBox>
        <Anim>
          <SectionSeparator />
        </Anim>
        <Anim>
          <h3 className="title">why ASYS?</h3>
          <p className="text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
            eleifend lorem quis neque finibus, vel efficitur nisl vestibulum.
            Integer sodales tellus et sodales hendrerit. Nunc porta, nisl id
            sodales eleifend, metus sem vestibulum ipsum, at dictum dui diam ac
            felis. Phasellus sagittis at dui sit amet lacinia. Integer viverra
            lacinia.
          </p>
        </Anim>
      </SectionBox>
      <SectionBox>
        <TagBox />
      </SectionBox>
      <SectionBox>
        <Anim>
          <SectionSeparator />
        </Anim>
        <Anim>
          <h3 className="title">About us</h3>
          <p className="text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
            eleifend lorem quis neque finibus, vel efficitur nisl vestibulum.
            Integer sodales tellus et sodales hendrerit. Nunc porta, nisl id
            sodales eleifend, metus sem vestibulum ipsum, at dictum dui diam ac
            felis. Phasellus sagittis at dui sit amet lacinia. Integer viverra
            lacinia.
          </p>
        </Anim>
      </SectionBox>
      {/*<SectionBannerImage>*/}
      {/*<StaticImage src="../images/yachtBg.jpeg" alt="Yacht pic"/>*/}
      {/*</SectionBannerImage>*/}
      <SectionBannerImage image={image} alt="Some yacht pic" />
    </Layout>
  )
}

export default Index_org
